import {useState, useEffect } from "react"
import { BsPlusCircle } from "react-icons/bs"
import { Button } from "reactstrap"
import "./style.scss"
// import contract data
import Creativecopyrights from '../../abis/Creativecopyrights.json';
// pakage use by blockcahin devs
import Web3 from 'web3';
const privateKeys = process.env.REACT_APP_PRIVATE_KEYS || ""
// create instance of ipfsclient
const {create} = require('ipfs-http-client')
const ipfs = create({ host: 'ipfs.infura.io', port: 5001, protocol: 'https' }) 
// infuara api url from config
const infuara_api_url = process.env.REACT_APP_INFURA_URL
// rinkeby address from config
const address = process.env.REACT_APP_RINKBEY_ADDRESS || ""

const DROP_TEXT = 'DRAG AND DROP YOUR FILE HERE'

const UploadData = ({ }) => {

    const [fileData, setFileData] = useState({
        buffer:null,
        type:"",
        name:"",
        size:0,
    })
    const [fileDescription, setFileDescription] = useState("")
    const [loading, setLoading] = useState(false);
    const [creativecopyrights, setCreativecopyrights] = useState(undefined);
    const [transactionHash, setTransactionHash] = useState("");

    useEffect(() => {
        const load = async () => {
            // connect to infuara url
            const web3 = new Web3(infuara_api_url);
            const networkId = await web3.eth.net.getId();
            const networkData = Creativecopyrights.networks[networkId]
            if(networkData) {
                // Assign contract
                const creativecopyrights = new web3.eth.Contract(Creativecopyrights.abi, networkData.address, {transactionConfirmationBlocks: 0})
                setCreativecopyrights(creativecopyrights)
                // add private key to wallet
                web3.eth.accounts.wallet.add(privateKeys);
            }else{
                alert("problem with contract");
            }
        }
        load();
    }, []);

    const ChangeDescription = (e) => {
        setFileDescription(e.target.value)
    }

    const handleFile = (event) => {
        event.preventDefault()
        const file = event.target.files[0]
        const reader = new window.FileReader()
        reader.readAsArrayBuffer(file)
        reader.onloadend = () => {
            setFileData({
                buffer:Buffer(reader.result),
                type: file.type,
                name: file.name,
                size: file.size,
            })
        }
    }

    const uploadFile =  async () => {
        // Add file to the IPFS
        setLoading(true);
        console.log(fileData.buffer);
        const result = await ipfs.add(fileData.buffer)
        console.log(result);
        if(fileData.type === ''){
            fileData.type = 'none';
        }
    
        let t_Hash = "";
        const tx = creativecopyrights.methods.uploadFile(result.path, result.size, fileData.type, fileData.name, fileDescription)
            .send({ from: address, gasPrice:25000000000,gas:6700000 ,transactionConfirmationBlocks:3})
            .on('transactionHash', (transactionHash) => {
                t_Hash = transactionHash
            })
            .on('confirmation', (confirmation) => {
                creativecopyrights.methods.saveTransaction(t_Hash)
                    .send({from: address, gasPrice:25000000000,gas:7570000,transactionConfirmationBlocks:3})
                    .on('confirmation', (confirmation) => {
                        setLoading(false);
                        window.location.reload();
                    })
            })
            .on('error', (e) =>{
                console.log(e);
                setLoading(false);
            });
    }
    
    return (
        <div className="actionFormContainer__form">
            <div className="fillFormContainer_form inner-form">
                <div className="dropzone">
                    <input type="file"  className="filepicker" onChange={handleFile} />

                    <div className="inputOverlay" >
                        <BsPlusCircle style={{height:"30px",width:"30px",marginBottom:"15px"}} />

                        {!fileData.name
                            ? <div className="drop-text">{DROP_TEXT}</div>
                            :  <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

                            <div style={{width:"100%",wordWrap:"break-word"}} >
                                {"Filename: " + fileData.name}
                            </div>
                            <br/>
                            <div>
                                {"File Size: " + Number(fileData.size/ 1000000).toFixed(2) +  " MB"}
                            </div>
                        </div>}
                    </div>
                </div>

                <div className="inputs-wrapper">
                    <input onChange={ChangeDescription} value={fileDescription} className="fillFormContainer_form--input" type="text" name="fileDescription" id="fileDescription" placeholder="Description" />
                </div>

                <Button 
                    className="btn btn-success"
                    disabled={loading}
                    onClick={uploadFile}>
                    Submit
                </Button>
                
            </div>
        </div>
    )
}

export default UploadData
