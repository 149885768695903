import styled from 'styled-components';
import MainButton from '../MainButton'
import classnames from 'classnames';
import Asset from './ILLU_Frontpage.png'

const Wrapper = styled.div`
    color: #fff;
    padding: 20px 0;
    margin-bottom: 0px;
`;

const Title = styled.div`
    font-size: 36px;
    font-weight: bold;
    // margin-bottom: 10px;
    // cursor: cursor;
    user-select: none;

    // &:after {
    //     content: '';
    //     display: inline-block;
    //     width: 80px;
    //     height: 35px;
    //     background: url('./assets/chevron.png') no-repeat center / contain;
    //     filter: invert(1);
    //     position: relative;
    //     top: 5px;
    //     transition: .3s ease all;
    // }

    &.toggled:after {
        transform: rotate(90deg);
        transition: .3s ease all;
    }
`;

const Text = styled.div`
    font-size: 18px;
`;
const TextGroup = styled.div`
    margin-bottom: 45px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 500px;

    a {
        text-transform: uppercase;
    }
`;

const BottomText = styled.div`
    color: #73dddd;
    font-size: 20px;
    padding-top: 75px;
`;

const Illustration = styled.div`
    width: 100%;
    max-width: 750px;
    height: 180px;
    margin: 0 0 20px;
    background-image: url(${Asset});
    background-size: contain;
    background-repeat: no-repeat;
`;

const HomepageText = ({ content }) => {
    const classname = classnames('headline', { })
    const title = 'PROTECT YOUR CREATIVE WORK';
    const text = "<br />We believe in empowering creatives to be in full control of their copyrights. This application is a tool for any creative to give you a fraud resistant proof of your ownership. The good thing: it can be validated by anyone anytime worldwide."
    return (
        <Wrapper>
            <Illustration/>
            <div className="WelcomeTextContainer" >
                <TextGroup>
                    <Title className={classname}>{title}</Title>
                    <Text dangerouslySetInnerHTML={{ __html: text }}></Text>
                </TextGroup>

                <ButtonWrapper>
                    <MainButton text="HOW IT WORKS" to="/how-it-works"/>
                    <MainButton text="GET STARTED" to="/get-started" />
                </ButtonWrapper>

                <BottomText>No Subscription - No Registration - Just Pay As You Go.</BottomText>

            </div>
        </Wrapper>
    )
}

export default HomepageText
