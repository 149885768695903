import axios from "axios"
import { API_URL } from '../../config'

const redirectToLogin = () => window.location.href = '/admin'

const authHeader = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.accessToken) {
        return {
            'x-access-token': user.accessToken
        };
    } else {
        return {};
    }
}

// Admin API
export const adminLogin = ({ email, password }) => {
    const body = { email, password }

    return axios.post(`${API_URL}/admin/signin`, body)
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.status === 200;
        })
        .catch(redirectToLogin)
}

export const getAllUsers = () => {

    return axios.get(`${API_URL}/admin/users`, { headers: authHeader() })
        .then((response) => {
            if (response.status === 200) {
                return response
            } else {
                window.location.href = '/admin'
            }
        })
        .catch(redirectToLogin)
}

export const getAllCoupons = () => {

    return axios.get(`${API_URL}/admin/coupons`, { headers: authHeader() })
        .then((response) => {
            if (response.status === 200) {
                return response
            }
        })
}

export const setNewCoupon = ({ email, couponCode }) => {
    const body = { email, couponCode }

    return axios.post(`${API_URL}/admin/addcoupon`, body, { headers: authHeader() })
        .then((response) => {
            return response.status === 200;
        });
}

export const removeCoupon = (couponCode) => {
    const body = { couponCode }

    return axios.delete(`${API_URL}/admin/removecoupon`, { headers: authHeader(), data: body })
        .then((response) => {
            return response.status === 200;
        });
}

export const getAllContent = ({ page }) => {

    return axios.get(`${API_URL}/admin/content`, { headers: authHeader(), params: { page } })
        .then((response) => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(redirectToLogin)
}

export const setNewContent = (content) => {
    const body = content

    return axios.post(`${API_URL}/admin/content`, body, { headers: authHeader() })
        .then((response) => {
            return response.status === 200;
        });
}
