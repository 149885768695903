import React from 'react'
import { Link } from "react-router-dom"
import MainButton from '../../components/MainButton'
import "./Footer.scss"

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__container" >
                <ul >
                    <li>
                        <a role="button" className="footerLink__pressKit" onClick={(e) => {
                            window.open("mailto:business@creativecopyrights.com?subject=Media Request", "_blank");
                            e.preventDefault();
                        }}>PRESS KIT</a>
                    </li>
                    <li>
                        <Link className="footerLink" to="/impressum">IMPRESSUM</Link>
                    </li>
                    <li>
                        <a className="footerLink" href="https://www.websitepolicies.com/policies/view/tU9r5hOR" target="_blank">TERMS & CONDITIONS</a>
                    </li>
                    <li>
                        {/* <Link className="footerLink" to="/dataprotection" target="_blank">DATA PROTECTION</Link> */}
                        <a className="footerLink" href="https://www.websitepolicies.com/policies/view/LtYdV74E" target="_blank">PRIVACY POLICY</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Footer
