import {useState } from "react"
import styled from 'styled-components';
import { BsPlusCircle } from "react-icons/bs";
import VerifyCode from '../VerifyCode'
import { validateEmail, createHashFromFile } from '../../../utils'
import { verifyEMail, verifyConfirmationCode, resendConfirmationEmail } from '../../../api'
import { raiseToast } from '../../../utils/toast'
import "./style.scss"

const DROP_TEXT = 'DRAG AND DROP YOUR FILE HERE'

const NoEmailSendAgain = styled.div`
    color: #000;
    text-align: left;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
`;

const InnerWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Button = styled.button`
    font-family: "ConneqtRegular";
    width: 100%;
    max-width: 220px;
    padding: 12px;
    margin-top:20px;
    text-align: center;
    color: #fff;
    font-size: 24px;
    letter-spacing: 1.8px;
    border-radius: 25px;
    background: rgb(45,129,201);
    background: linear-gradient(90deg, rgba(45,129,201,1) 0%, rgba(17,215,216,1) 100%);
    position: relative;
    z-index: 2;
    text-transform: uppercase;

    &:disabled {
        opacity: 0.5;
    }
`;


const FillForm = ({ setActiveStage, email, setEmail, setName, setFile, file, name }) => {

    
    const [confirmationCode, setCode] = useState("")
    const [isEmailSent, setIsEmailSent] = useState(false)

    const handleFile = ({ target }) => {
        const file = target.files[0]
        const hash = createHashFromFile(file)

        setFile(file)
    }

    const ChangeName = (e) => {
        setName(e.target.value)
    }

    const ChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleVerifyCode = (code) => {
        setCode(code)

        if (code.length === 6) {
            handleCheckout(code)
        }
    }

    const sendVerifyMail = () => {
        verifyEMail({ name, email })
        .then((isSucceed) => {
            if (isSucceed) {
                raiseToast('Email with code has sent.')
                setIsEmailSent(true)
            } else {
                raiseToast('Something wrong, try again.')
            }
        })
    }

    const isValid = file.name && name !== "" && validateEmail(email);

    const handleCheckout = (code) => {
        verifyConfirmationCode(code)
        .then((isVerified) => {
            if (isVerified) {
                setActiveStage(1)
            } else {
                alert('Wrong code, try again.')
            }
        })
    }

    const sendConfirmEmailAgain = () => {
        resendConfirmationEmail(email)
        .then((isVerified) => {
            if (isVerified) {
                alert('Email with code has sent.')
            } else {
                alert('Something wrong. try again.')
            }
        })
    }

    return (
        <div className="fillFormContainer_form inner-form">
            <div className="dropzone">
                <input type="file"  className="filepicker" onChange={handleFile} />

                <div className="inputOverlay" >
                    <BsPlusCircle style={{height:"30px",width:"30px",marginBottom:"15px"}} />

                    {!file.name
                        ? <div className="drop-text">{DROP_TEXT}</div>
                        :  <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}} >

                        <div style={{width:"100%",wordWrap:"break-word"}} >
                            {"Filename: " + file.name}
                        </div>
                        <br/>
                        <div>
                            {"File Size: " + Number(file.size/ 1000000).toFixed(2) +  " MB"}
                        </div>
                    </div>}
                </div>
            </div>

            <div className="inputs-wrapper">
                <input onChange={ChangeName} value={name} className="fillFormContainer_form--input" type="text" name="name" id="name" placeholder="YOUR NAME *" />
                <input onChange={ChangeEmail} value={email}  className="fillFormContainer_form--input"  type="email" name="email" id="email" placeholder="YOUR EMAIL ADRESS *" />
            </div>
            
            <VerifyCode
                disabled={!isValid}
                setValue={handleVerifyCode}
                isEmailSent={isEmailSent}
                sendVerifyMail={sendVerifyMail}/>

            <NoEmailSendAgain onClick={sendConfirmEmailAgain}>No email? send again.</NoEmailSendAgain>
            <InnerWrap>
                <Button disabled={true}>TO PAYMENT</Button>
            </InnerWrap>
        </div>
    )
}

export default FillForm
