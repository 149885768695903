import { useState } from "react"
import { adminLogin } from '../../api'
import { useHistory } from "react-router-dom";

import "./style.scss"

const Login = () => {
    const history = useHistory();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const submitLogin = (e) => {
        e.preventDefault()

        adminLogin({ email, password })
        .then((isVerified) => {
            if (isVerified) {
                history.push("/admin/home")
            } else {
                alert('Wrong credentials.')
            }
        })
    }

    const onChangeEmail = (e) =>{
        setEmail(e.target.value)
    }

    const onChangePassword = (e) =>{
        setPassword(e.target.value)
    }

    return (
        <div className="loginContainer" >
            <h2>Admin Login</h2>

            <form className="loginContainer__form" onSubmit={submitLogin} >
                <input className="loginContainer__form--input" required type="email" placeholder="Email" onChange={onChangeEmail}/>
                <input className="loginContainer__form--input" required type="password" placeholder="Password" onChange={onChangePassword} id="password"/>
                <input className="loginContainer__form--input" type="submit" name="button" value="Login" id="button"/>
            </form>
        </div>
    )
}

export default Login
