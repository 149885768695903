
// CSS
import "./Impressum.css"
import styled from 'styled-components';
import classnames from 'classnames';

const Title = styled.div`
    font-size: 36px;
    font-weight: bold;
    // margin-bottom: 10px;
    // cursor: cursor;
    user-select: none;

    // &:after {
    //     content: '';
    //     display: inline-block;
    //     width: 80px;
    //     height: 35px;
    //     background: url('./assets/chevron.png') no-repeat center / contain;
    //     filter: invert(1);
    //     position: relative;
    //     top: 5px;
    //     transition: .3s ease all;
    // }

    &.toggled:after {
        transform: rotate(90deg);
        transition: .3s ease all;
    }
`;

const Wrapper = styled.div`
    color: #fff;
    padding: 40px 0;
    margin-bottom: 0px;
`;

const Impressum = () => {
    const classname = classnames('headline')

    return(
        <Wrapper>
            <div className="impressum__Container" >
                <Title className={classname}>IMPRESSUM</Title>
                <br/>
                <div>CREATIVE COPYRIGHTS UG (Haftungsbeschränkt)</div>
                <br/>
                <div>HRB 259727</div>
                <br/>
                <div>USt-IdNr.</div>
                <div>DE335810805</div>
                <br/>
                <div>Managing Director:   Karolina Dabrowski</div>
                <br/>
                <div>Reifenstuelstrasse 7</div>
                <div>80469 Munich</div>
                <div>Germany</div>
            </div>
        </Wrapper>
    )
}


export default Impressum
