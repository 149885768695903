import { Link } from "react-router-dom"
import styled from 'styled-components';

const Button = styled.button`
    background-color: transparent;
    color: white;
    border: 1px solid white;
    font-size: 20px;
    line-height: 100%;
    padding: 8px 30px;
    text-transform: uppercase;
    transition: .3s ease all;

    &:hover {
        background-color: #fff;
        color: #000;
    }
`;

const MainButton = ({ text, to = '/', onClick = null, mailto= null }) => {
    if(!mailto){
        if (onClick) {
            return (
                <Button className="btn styled" onClick={onClick}>{text}</Button>
            )
        } else {
            return (
                <Link  to={to}>
                    <Button className="btn styled">{text}</Button>
                </Link>
            )
        }
    }else{
        return (
            <Link  to='#'
                onClick={(e) => {
                    window.open(mailto, "_blank");
                    e.preventDefault();
                }}>
                <Button className="btn styled">{text}</Button>
            </Link>
        )
    }
}

const ButtonNew = styled.button`
    background-color: transparent;
    color: white;
    // border: 1px solid white;
    font-size: 20px;
    line-height: 100%;
    padding: 8px 30px;
    text-transform: uppercase;
    transition: .3s ease all;
    text-decoration: underline;
    cursor: pointer;
    
    &:hover {
        background-color: #fff;
        color: #000;
    }
`;

export const ButtonClick = ({ text, to = '/', onClick = null }) => {
    if (onClick) {
        return (
            <ButtonNew className="btn styled" onClick={onClick}>{text}</ButtonNew>
        )
    } else {
        return (
            <Link to={to}>
                <ButtonNew className="btn styled">{text}</ButtonNew>
            </Link>
        )
    }
}

export default MainButton
