import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Asset from './Illu.png'
import MainButton from '../MainButton'
import { ButtonWrapper } from '../HomepageText'
import { getPageContent } from '../../api/cms'
import classnames from 'classnames';

const Wrapper = styled.div`
    color: #fff;
    padding: 40px 0;
    margin-bottom: 0px;
`;

const Title = styled.div`
    font-size: 36px;
    font-weight: bold;
    // margin-bottom: 10px;
    // cursor: cursor;
    user-select: none;

    // &:after {
    //     content: '';
    //     display: inline-block;
    //     width: 80px;
    //     height: 35px;
    //     background: url('./assets/chevron.png') no-repeat center / contain;
    //     filter: invert(1);
    //     position: relative;
    //     top: 5px;
    //     transition: .3s ease all;
    // }

    &.toggled:after {
        transform: rotate(90deg);
        transition: .3s ease all;
    }
`;
const Illustration = styled.div`
    width: 100%;
    max-width: 750px;
    height: 180px;
    margin: 0 0 30px;
    background-image: url(${Asset});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

const Text = styled.p`
    font-size: 18px;
    line-height: 130%;
    margin-bottom: 40px;
    white-space: break-spaces;
`;

const HowItWorks = () => {
    const [content, setContent] = useState({})
    const classname = classnames('headline', { })

    useEffect(() => {
        getPageContent({ page: 'how-it-works' })
        .then(({ data }) => setContent({ ...content, ...data[0] }))
    }, [])

    return (
        <div className="WelcomeTextContainer" >
            <Wrapper>
                <Title className={classname}>HOW IT WORKS</Title>
                <Illustration/>

                <Text dangerouslySetInnerHTML={{ __html: content.headlineFirst }}/>

                <ButtonWrapper>
                    <MainButton text="use cases" to="/use-cases"/>
                    <MainButton text="GET STARTED" to="/get-started"/>
                </ButtonWrapper>
            </Wrapper>
        </div>
    )
}

export default HowItWorks
