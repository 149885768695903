import React from 'react'
import styled from 'styled-components';

const Wrapper = styled.div`
    margin: 25px auto;

    input {
        border: 1px solid #b0bab8;
    }
`;

const InnerWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Button = styled.button`
    font-family: "ConneqtRegular";
    width: 100%;
    max-width: 220px;
    padding: 10px;
    color: #fff;
    font-size: 24px;
    letter-spacing: 1.8px;
    border-radius: 25px;
    background: rgb(45,129,201);
    background: linear-gradient(90deg, rgba(45,129,201,1) 0%, rgba(17,215,216,1) 100%);
    position: relative;
    z-index: 2;
    text-transform: uppercase;

    &:disabled {
        opacity: 1;
    }
`;

const VerifyCode = ({ sendVerifyMail, setValue, isEmailSent, disabled }) => {
    const buttonText = isEmailSent ? 'verify' : 'get code'
    const placeholderText = 'ENTER CODE*'

    return (
        <Wrapper>
            <InnerWrap>
                <Button onClick={sendVerifyMail} disabled={disabled}>{buttonText}</Button>
                <input type="type" className="verify--code"
                    disabled={!isEmailSent}
                    onChange={({target}) => setValue(target.value)}
                    name="verifyCode" placeholder={placeholderText} />
            </InnerWrap>
        </Wrapper>
    )
}

export default VerifyCode;
