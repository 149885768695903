import Login from "./login"
import styled from 'styled-components';

const Wrapper = styled.div`
    min-height: 100vh;
`;

const Administration = () => (
    <Wrapper>
        <Login/>
    </Wrapper>
)

export default Administration
