import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import MainButton from '../../components/MainButton'
import { getPageContent } from '../../api/cms'

const Items = [
    {
        title: 'headlineFirst',
        text: 'textFirst'
    },
    {
        title: 'headlineSecond',
        text: 'textSecond'
    },
    {
        title: 'headlineThird',
        text: 'textThird'
    },
    {
        title: 'headlineFourth',
        text: 'textFourth'
    }
]

const Wrapper = styled.div`
    color: #fff;
    padding: 20px 0;
    margin-bottom: 20px;
`;

const Title = styled.div`
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
    user-select: none;

    &:after {
        content: '';
        display: inline-block;
        width: 80px;
        height: 35px;
        background: url('./assets/chevron.png') no-repeat center / contain;
        filter: invert(1);
        position: relative;
        top: 5px;
        transition: .3s ease all;
    }

    &.toggled:after {
        transform: rotate(90deg);
        transition: .3s ease all;
    }
`;

const ReadMore = styled.div`
    font-size: 20px;
    text-decoration: underline;
    cursor: pointer;
`;

const Text = styled.div`
    p {
        font-size: 18px;
        line-height: 120%;
        padding: 10px 0 30px;
        white-space: break-spaces;
    }
`;

const UseCases = () => {
    const [content, setContent] = useState({})

    useEffect(() => {
        getPageContent({ page: 'use-cases' })
        .then(({ data }) => setContent({ ...content, ...data[0] }))
    }, [])

    return (
        <Wrapper>
            {Items.map(({ title, text }, key) => (<UseCaseItem title={content[title]} text={content[text]} key={key}/>))}
        </Wrapper>
    )
}

const UseCaseItem = ({ title, text }) => {
    const [isVisible, setIsVisible] = useState(false)
    const classname = classnames('headline', { 'toggled': isVisible })

    return (
        <Wrapper>
            <Title className={classname} onClick={()=>setIsVisible(!isVisible)}>{title}</Title>
            {!isVisible && <ReadMore onClick={()=>setIsVisible(!isVisible)}>Read more</ReadMore>}
            {isVisible &&
                <Text>
                    <p>{text}</p>
                    <MainButton text="GET STARTED" onClick={null} to="/get-started"/>
                </Text>}
        </Wrapper>
    )
}

export default UseCases
