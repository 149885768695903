import sha256 from 'crypto-js/sha256';

export const createHashFromFile = (file = null) => {
    let hash;
    const reader = new FileReader()

    reader.onload = function () {
        hash = sha256(reader.result)
    }

    if (file) {
        reader.readAsDataURL(file)
    }

    return hash;
}

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
