import axios from "axios"
import { API_URL } from '../config'

export const getPageContent = ({ page }) => {

    return axios.get(`${API_URL}/cms/content`, { params: { page } })
        .then((response) => {
            if (response.status === 200) {
                return response
            } else {

            }
        })
        .catch(err => {});
}
