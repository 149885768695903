import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import MainButton from '../components/MainButton'
import firebase from "firebase";

const Wrapper = styled.div`
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: left;
    min-height: 100vh;
    flex-direction: column;
    color: white;
    font-size: 150%;
    max-width: 980px;
    margin-left: auto;
    padding: 12px 18px;

    * {
        box-sizing:border-box;
    }

    .btn {
        max-width: 180px;

        &:hover {
            transform: scale(1);
        }

        &:focus {
            color: black;
            background-color: white;
        }

        @media (min-width: 520px) {
            max-width: 260px;
        }
    }
`;

const Logo = styled.div`
    background: url('./assets/logo.png') no-repeat center / contain;
    width: 220px;
    height: 90px;
    margin: 24px auto 24px;
    opacity: .5;

    @media (min-width: 520px) {
        float: right;
        margin-left: -150px;
    }
`;

const MainTitle = styled.div`
    font-family: 'ConneqtRegular';
    font-size: 6.5vw;
    line-height: 120%;
    letter-spacing: 2.43px;
    text-transform: uppercase;
    margin-bottom: 120px;
    text-align: left;
    position: relative;
    padding-right: 50px;

    @media (min-width: 520px) {
        font-size: 44px;
        line-height: 110%;
        margin-bottom: 76px;
        padding-right: 0;
    }
`;

const Text = styled.div`
    font-size: 4.5vw;
    white-space: break-spaces;
    max-width: 770px;
    margin-bottom: 28px;
    letter-spacing: 1.2px;
    line-height: 120%;
    font-weight: bold;

    @media (min-width: 520px) {
        line-height: 110%;
        font-size: 24px;
        margin-bottom: 22px;
    }
`;

const Input = styled.input`
    border: solid 1px #000000;
    background-color: #ffffff;
    width: 100%;
    max-width: 470px;
    height: 48px;
    text-transform: uppercase;
    padding-left: 12px;
    font-size: 16px;
    font-family: Myriad Pro;
    margin-bottom: 22px;
    border: 0;

`;

const AdditionalTxt = styled.div`
    font-size: 4.8vw;
    letter-spacing: 1.3px;
    border-bottom: 2px solid white;
    font-family: 'Architects Daughter', cursive;
    position: relative;
    top: 0;
    right: 0;
    z-index: 1;
    width: fit-content;
    text-align: center;
    margin: 60px auto -50px;
    left: 25px;

    &:before {
        content: '';
        display: inline-block;
        width: 100px;
        height: 100px;
        background: url('./assets/arrow_mobile.png') no-repeat left;
        background-size: contain;
        position: absolute;
        top: -100px;
        left: 85%;
        z-index: 1;
    }

    @media (min-width: 520px) {
        position: absolute;
        font-size: 26px;
        top: auto;
        right: -20px;
        left: auto;
        margin: 0;
        margin-top: -20px;

        &:before {
            width: 50px;
            height: 50px;
            background: url('./assets/arrow.png') no-repeat left;
            background-size: contain;
            top: -16px;
            left: -60px;
        }
    }
`;

const Footer = styled.div`
    letter-spacing: 0.9px;
    font-size: 12px;
    padding: 75px 0;
    color: white;
    position: static;

    div {
        margin-left: auto;
        cursor: pointer;
    }

    @media (min-width: 520px) {
        padding: 0 36px;
        text-align: right;
        position: absolute;
        bottom: 28px;
        right:0;
        z-index:1;
    }
`;

const Imprsm = () => (
    <Text>
        <p>
            CREATIVE COPYRIGHTS UG (Haftungsbeschränkt)<br/><br/>

            HRB 259727<br/><br/>

            USt-IdNr.
            DE335810805<br/><br/>

            Managing Director: Karolina Dabrowski<br/><br/>

            business@creativecopyrights.com</p>
    </Text>
)

const ThankYou = styled.div`
    font-size: 20px;
    font-weight: bold;
    background-color: white;
    color: black;
    border-radius: 12px;
    padding: 16px 20px;
    width: fit-content;
`;

export default function LandingPage() {
    const [page, setPage] = useState('main');
    const [email, setemail] = useState('');
    const [isRegistered, setisRegistered] = useState(false);

    useEffect(() => {
        if (firebase.apps.length !== 0) return

        firebase.initializeApp({
            apiKey: "AIzaSyC0laB-E4oCugK-gDMCdDDG59rQInNaAyU",
            authDomain: "kar-cc-eth.firebaseapp.com",
            projectId: "kar-cc-eth"
        });

    }, [])

    const handleChange = ({target}) => {
        setemail(target.value)
    }

    const handleSubmit = () => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {

            const db = firebase.firestore();

            db
            .collection("lp-registers")
            .add({
                email
            })
            .then(() => {
                setisRegistered(true)
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
            });

        } else {
            return alert('You have entered an invalid email address.')
        }
    }

    const setImprs = () => {
        window.history.pushState({page: 'impressum'}, "impressum", "/impressum")
        setPage('imprs')

        window.onpopstate = (event) => {
            setPage('main')
        }
    }

    return (
        <>
            <Logo/>
            <Wrapper>

                {page === 'main' ? <><MainTitle>
                    PROTECT YOUR CREATIVE WORK on blockchain.
                    <AdditionalTxt>2D, 3D, AUDIO, VIDEO, TEXT</AdditionalTxt>
                </MainTitle>

                <Text>
                    It was never so easy to steal designs off the web.<br/>
                    It was never so easy to protect your designs through the web.<br/><br/>

                    Stored on Ethereum. Fraud-Resistant.<br/>
                    No uploading of your file to a server - your work stays with you.<br/><br/>

                {!isRegistered && <>Coming soon. Get notified for the launch!</>}
                </Text>

                {!isRegistered ? <>
                    <Input type="email" name="email" onChange={handleChange} placeholder="ENTER YOUR E-MAIL ADDRESS"/>
                    <MainButton onClick={handleSubmit} text="notify me"/></>
                        : <ThankYou className="fade-in-animate">
                            Registered successfully.<br/><br/>
                            You will get notified for the launch!
                        </ThankYou>}

                </> : <Imprsm/>}

                <Footer>
                    <div onClick={()=>setImprs(true)}>IMPRESSUM</div>
                </Footer>

            </Wrapper>

        </>
    )
}
