import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { verifyCertificate } from '../../api/index';
import { getPageContent } from '../../api/cms'
import Moment from 'react-moment';
import moment from 'moment';
import classnames from 'classnames';
import "./VerifyAFiling.css"
import "./style.scss"
import { raiseToast } from '../../utils/toast';

const Wrapper = styled.div`
    color: #fff;
    padding: 40px 0;
    margin-bottom: 0px;
`;

const Title = styled.div`
    font-size: 36px;
    font-weight: bold;
    // margin-bottom: 10px;
    // cursor: cursor;
    user-select: none;

    // &:after {
    //     content: '';
    //     display: inline-block;
    //     width: 80px;
    //     height: 35px;
    //     background: url('./assets/chevron.png') no-repeat center / contain;
    //     filter: invert(1);
    //     position: relative;
    //     top: 5px;
    //     transition: .3s ease all;
    // }

    &.toggled:after {
        transform: rotate(90deg);
        transition: .3s ease all;
    }
`;

const Text = styled.div`
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 30px;
    white-space: break-spaces;
`;

const Input = styled.input`
    height: 40px;
    width: 50%;
    padding: 5px 5px 5px 20px;
    background-color: #f0f8f7;
    border: 1px solid #b0bab8;
`;

// const Button = styled.button`
//     font-family: "ConneqtRegular";
//     padding: 16px 40px;
//     letter-spacing: 1.5px;
//     font-weight: bolder;
//     color: white;
//     font-size: 20px;
//     background: rgb(45,129,201);
//     background: linear-gradient(90deg, rgba(45,129,201,1) 0%, rgba(17,215,216,1) 100%);
//     border-style: none;
//     border-radius: 20px;
//     position: relative;
//     z-index: 2;
//     right: 20px;
//     text-transform: uppercase;
// `;

const Button = styled.button`
    font-family: "ConneqtRegular";
    width: 100%;
    max-width: 220px;
    padding: 10px;
    color: #fff;
    font-size: 24px;
    letter-spacing: 1.8px;
    border-radius: 25px;
    background: rgb(45,129,201);
    background: linear-gradient(90deg, rgba(45,129,201,1) 0%, rgba(17,215,216,1) 100%);
    position: relative;
    z-index: 2;
    text-transform: uppercase;

    &:disabled {
        opacity: 1;
    }
`;

const VerifyAFiling = () => {
    const [content, setContent] = useState({})
    const [filterData, setFilterData] = useState("");
    const [verificationData, setVerificationData] = useState();

    const ChangeFilterData = (e) => {
        setFilterData(e.target.value)
    }

    const verifyFile = () => {
        verifyCertificate(filterData).then((response) => {
            if(response?.data == ""){
                raiseToast("No Data Found");
            }else{
                setVerificationData(response?.data)
            }
            if(response == undefined){
                raiseToast("No Data Found");    
            }
        }).catch((err) => {
            raiseToast("No Data Found");
        })

    }

    const humanData = (time_stamp) =>{
        const new_date =  new Date(time_stamp*1000);
        console.log(new_date)
        return 123;
    }

    useEffect(() => {
        getPageContent({ page: 'verify-file' })
        .then(({ data }) => setContent({ ...content, ...data[0] }))
    }, [])

    const classname = classnames('headline')

    return (
        <>
            <Wrapper>
                <Title className={classname}>{content.headlineFirst}</Title>

                <Text dangerouslySetInnerHTML={{ __html: content.textFirst }}/>

                <Input onChange={ChangeFilterData} value={filterData} className="fillFormContainer_form--input" type="text" name="filterData" id="filterData" placeholder="Enter transaction code OR certificate ID" />

                <Button onClick={verifyFile}>verify</Button>
                {verificationData && verificationData!= ''&&(
                    <div className="actionFormContainer_verify" >
                        <div className="actionFormContainer__form" >
                            <div className="fillFormContainer_form inner-form">
                                <p className="text-muted text-start">OwnerName:</p>
                                <p className="text-style mt-5">{verificationData.userName}</p>
                                <p className="text-muted text-start mt-5">OwnerEmail:</p>
                                <p className="text-style mt-5">{verificationData.userEmail}</p>
                                <p className="text-muted text-start mt-5">DateTime:</p>
                                <p className="text-style mt-5">{moment(verificationData.dateTime*1000).format("MM/DD/YYYY, HH:mmA")}</p>
                            </div>
                        </div>
                    </div>
                )}
            </Wrapper>
        </>
    )
}

export default VerifyAFiling
