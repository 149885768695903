import React from 'react'
import classnames from 'classnames'
import "./style.scss"

const STAGES = [
    '1 Fill Form',
    '2 Payment',
    '3 Certificate',
]

export default function Breadcrumbs({ activeStage }) {
    return (
        <div className="form-nav">
            {STAGES.map((stage, idx) => {
                const classname = classnames({ 'active': activeStage === idx })
                
                return (
                    <span className={classname} key={idx}>{stage}</span>
                )})}
        </div>
    )
}
