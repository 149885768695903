import { Container, Row, Col } from "reactstrap"
import "./style.scss"

const BodyForm = ({ childrenLeft, childrenRight }) => {

    return (
        <Container className="main-container" fluid>
            <Row>
                <Col xs="12" md="4">
                    <div className="bodyFormContainer__form">
                        {childrenLeft}
                    </div>
                </Col>

                <Col xs="12" md="8">
                    <div className="bodyFormContainer__body">
                        {childrenRight }
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default BodyForm
