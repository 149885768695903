import { useEffect, useState } from "react"
import moment from 'moment'
import { convertBytes } from './helper'
import './style.scss'
// import contract data
import Creativecopyrights from '../../abis/Creativecopyrights.json';
// pakage use by blockcahin devs
import Web3 from 'web3';
import { Table } from "reactstrap";
// get private key from config file
const privateKeys = process.env.REACT_APP_PRIVATE_KEYS || ""
// infuara api url from config
const infuara_api_url = process.env.REACT_APP_INFURA_URL

const FileData = ({}) => {

    const[fetchingFiles, setFetchingFiles] = useState(false);
    const[filesData, setFilesData] = useState([]);

    // we use useEffect to load web3 contract and fetch all files and it will fetch only on first load
    useEffect(() => {
        const load = async () => {
            // connect to infuara url
            const web3 = new Web3(infuara_api_url);
            const networkId = await web3.eth.net.getId();
            const networkData = Creativecopyrights.networks[networkId]
            if(networkData) {
                // Assign contract
                const creativecopyrights = new web3.eth.Contract(Creativecopyrights.abi, networkData.address, {transactionConfirmationBlocks: 0})
                // add private key to wallet
                web3.eth.accounts.wallet.add(privateKeys);
                // Get files amount
                const filesCount = await creativecopyrights.methods.fileCount().call()
                for (var i = filesCount; i >= 1; i--) {
                    const file = await creativecopyrights.methods.files(i).call()
                    const transaction = await creativecopyrights.methods.transaction(file.fileId).call();
                    if(transaction.transactionHash !== ''){
                        file.transactionHash = transaction.transactionHash;
                    }else{
                        file.transactionHash = "";
                    }

                    setFilesData(prevState => {
                        const list = [...prevState, file];
                        return list;
                    });
                }
            } else {
                window.alert('Creativecopyrights contract not deployed to detected network.')
            }

            setFetchingFiles(true);
        }
        if(!fetchingFiles){
            load();
        }

    },[])

    return (
        <Table striped bordered hover variant="dark">
            <thead>
                <tr>
                    <th>id</th>
                    <th>name</th>
                    <th>description</th>
                    <th>type</th>
                    <th>size</th>
                    <th>date</th>
                    <th>transactionHash</th>
                    <th>uploader/view</th>
                    <th>hash/view/get</th>
                </tr>
            </thead>
            <tbody style={{background:"white"}}>
            {fetchingFiles?filesData?.map((file, key) => {
                return(
                    <tr key={key}>
                        <td>{file.fileId}</td>
                        <td>{file.fileName}</td>
                        <td>{file.fileDescription}</td>
                        <td>{file.fileType}</td>
                        <td>{convertBytes(file.fileSize)}</td>
                        <td>{moment.unix(file.uploadTime).format('h:mm:ss A M/D/Y')}</td>
                        <td>
                            <strong>
                                <a
                                    style={{color: "#ff0000"}}
                                    href={"https://rinkeby.etherscan.io/tx/" + file.transactionHash}
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    {file.transactionHash.substring(0,10)}...
                                </a>
                            </strong>
                        </td>
                        <td>
                            <strong>
                                <a
                                    style={{color: "#ff0000"}}
                                    href={"https://etherscan.io/address/" + file.uploader}
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    {file.uploader.substring(0,10)}...
                                </a>
                            </strong>
                        </td>
                        <td>
                            <strong>
                                <a
                                    style={{color: "#ff0000"}}
                                    href={"https://ipfs.infura.io/ipfs/" + file.fileHash}
                                    rel="noopener noreferrer"
                                    target="_blank">
                                    {file.fileHash.substring(0,10)}...
                                </a>
                            </strong>
                        </td>
                    </tr>
                )
            }):""}
            </tbody>
        </Table>
    )
}

export default FileData
