import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import {
    Container, Row, Col, FormGroup, Label, Input, Button
} from 'reactstrap';
import {
    getAllContent, setNewContent
} from '../../api'

const Title = styled.div`
    font-size: 26px;
    font-weight: bold;
    margin: 30px 0;
`;

const Servicecost = styled.div`
    background-color: rgb(169 255 0 / 32%);
    border-radius: 8px;
    padding: 12px 22px;
    width: 180px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    position: relative;

    label {
        font-weight: bold;
    }

    input {
        padding-left: 22px;
        max-width: 100px;
    }

    span {
        position: absolute;
        left: 29px;
        bottom: 62px;
        z-index: 1;
    }
`;

const TextArea = styled.textarea`
    display: block;
    width: 100%;
    height: 100%;
    min-height: 200px;
    padding: 8px;
`;

export default function Pricing() {
    const [content, setContent] = useState({ page: 'pricing' })

    useEffect(() => {
        getAllContent({ page: 'pricing' })
        .then(({ data }) => setContent({ ...content, ...data[0] }))
    }, [])

    const handleChange = (key, value) => {
        setContent({ ...content, [key]: value })
    }

    const update = () => {
        setNewContent(content)
    }

    return (
        <Container>
            <Row>
                <Col xs="12" md="4">
                    <Servicecost>
                        <FormGroup>
                            <Label for="servicecost">Service Cost</Label>
                            <span>€</span>
                            <Input type="text" id="servicecost" defaultValue={content['servicecost']}
                                onChange={({target}) => handleChange('servicecost', target.value)}/>
                        </FormGroup>
                    </Servicecost>
                </Col>

                <Col xs="12" md="8">
                    <Title>Pricing content</Title>

                    {FORM_INPUTS.map(({ title, name, type }) => (
                        <FormGroup key={name}>
                            <Label for={name}>{title}</Label>
                            {type
                                ? <TextArea type="text" id={name} defaultValue={content[name]}
                                    onChange={({target}) => handleChange(name, target.value)}/>
                                : <Input type="text" id={name} defaultValue={content[name]}
                                    onChange={({target}) => handleChange(name, target.value)}/>}
                        </FormGroup>
                    ))}

                    <Button onClick={update}>Apply</Button>
                </Col>
            </Row>
        </Container>
    )
}

const FORM_INPUTS = [
    {
        title: 'Main Title',
        name: 'headlineFirst'
    },
    {
        title: 'Main Text',
        name: 'textFirst',
        type: 'txtarea'
    },
    {
        title: 'Package Title',
        name: 'headlineSecond'
    },
    {
        title: 'Package Text',
        name: 'textSecond'
    }
]
