import React from 'react';
import Header from './Header'
import Footer from './Footer'

const AppPageLayout = ({ children }) => (
    <>
        <Header/>
        {children}
        <Footer/>
    </>
);

export default AppPageLayout;
