//PDF
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"

// CSS
import styled from 'styled-components';
import "./Certificate.css"

import Placeholder from "../../../images/formular.png"
import { sendPdfEmail } from "../../../api";
import { raiseToast } from "../../../utils/toast";
import {logoImage, backgroundImage} from "../../../config/pdf_images";

pdfMake.vfs = pdfFonts.pdfMake.vfs;


const Wrapper = styled.div`
    padding: 35px 35px;
`;

const PDFIcon = styled.div`
    background:  url('./assets/PDF.png') no-repeat center / contain;
    height: 280px;
    width: 250px;
    margin: 20px auto 30px;
`;

// background: linear-gradient(130deg, rgba(4,241,88,1) 0%, rgba(4,241,73,1) 100%);
const Button = styled.button`
    display: block;
    width: 100%;
    max-width: 228px;
    margin: 0 auto;
    height: 50px;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #fff;
    background: rgb(4,241,88);
    background: linear-gradient(130deg,rgb(35, 134, 195) 0%,rgb(53, 191, 93) 100%);
    border-width: 0;
    border-radius: 50px;
`;



const Certificate = ( { fileData, email }) => {

    const onDownloadClick = () => {
        
        pdfMake.createPdf(docType).open()
    }

    const date = new Date().toUTCString()
    
    var docType = {
        watermark: { text: 'PDF created by Edlix Studios', color: 'white', opacity: 0.3, bold: true, italics: false },
        background:{
            image:"bg",
            fit: [340, 340],
            margin: [10,400,0,0]
        },

        content:[
            {margin: [0,0,0,30],fit: [140, 140], image: logoImage, style:"header"},
            { text:"CERTIFICATE", style:"header_1", margin: [0,0,0,0] },
            { text:"Congratulations for protecting your copyright!", style:"paragraph", margin:[0,20,0,0]},
            { text:`This certificate was issued on at ${date} and confirms that the file refered to below existed at that time and was submitted by the person below.`, style:"paragraph", margin:[0,10,0,0]},
            {
                style:'tableExample',
                table: {
                    headerRows: 1,
                    widths: [ 'auto', 'auto' ],
                    body: [
                      [ {text: 'NAME', style:'tableTd'}, {text:  fileData?.name, style:'tableTd2'} ],
                      [ {text: 'FILE NAME', style:'tableTd'}, {text: fileData?.file_name, style:'tableTd2'} ],
                      [ {text: 'FILE HASH', style:'tableTd'}, {text: fileData?.file_hash, style:'tableTd2'} ],
                      [ {text: 'Tx HASH', style:'tableTd'}, {text: fileData?.transactionHash, style:'tableTd2'} ],
                      [ {text: 'IPFS LINK', style:'tableTd'}, { text: "Click here to few file", link: "https://ipfs.infura.io/ipfs/"+fileData?.file_hash , style:'tableTd2'} ],
                    ],
                },
                layout: 'noBorders',
            },
            {text: 'please note !',italics: true, fontSize: 20, color:'red', margin: [10,310,0,0]},
            {text: 'INSTRUCTIONS FOR YOUR COPYRIGHTED FILE',italics: true, fontSize: 20, color:'#353030'},
            {text: 'Keep your certificate with your original file. Make sure to not alter, even slightly, the ',italics: false, fontSize: 10, color:'#91917D'},
            {text: 'file you have protected herewith. Only the original and unaltered file will be verified ',italics: false, fontSize: 10, color:'#91917D'},
            {text: 'with the file hash noted in this certificate.',italics: false, fontSize: 10, color:'#91917D'},
        ],
        styles:{
            header:{
                alignment:"right",
                color:"black",
            },
            header_1:{
                alignment:"left",
                color:"black",
                fontSize:32,
                bold:true
            },
            body:{
                alignment:"center",
                color:"#353030",
                fontSize:12,
                bold:true
            },
            paragraph:{
                color:"#91917D",
                fontSize:12,
            },
            tableExample: {
                margin: [0, 30, 0, 0]
            },
            tableTd:{
                fontSize:12,
                color:'#00000',
                margin: [0, 0, 0, 5]
            },
            tableTd2:{
                fontSize:12,
                color:'#00000',
                margin: [0, 0, 0, 5],
                // bold: true,
                lineHeight:1
            }
        },
        images: {
            bg: backgroundImage
        }
    }

    const pdfDocGenerator = pdfMake.createPdf(docType);
    pdfDocGenerator.getBase64((base64Pdf) => {
        sendPdfEmail({ email, base64Pdf, userName:fileData?.name, fileName:fileData?.file_name, fileHash:fileData?.file_hash, transactionHash:fileData?.transactionHash })
        .then((isSucceed) => {
            if (isSucceed) {
                raiseToast('Certificate has been send to email')
            } else {
                raiseToast('Something wrong, try again.')
            }
        })
    });




    return(
        // <div>
        //     <div className="certificateContainer" >
        //         {/* <p>HERE COMES INFO ABOUT THE CERTIFICATE</p>
        //         <p>WHAT'S IN THERE AND SO ON</p>
        //         <p>STORE IT SAFELY DIGITAL AND PRINT COPY</p>
        //         <p>YOU CAN ALWAYS GIVE YOUR TRANSCODE FOR VARIFICATION</p> */}
        //     </div>
        
        //     <div className="certificate--cert" >
        //         <img src={Placeholder} alt="Certificate" style={{maxWidth:"100%",maxHeight:"100%"}} />
        //     </div>



        //     <div className="certificate__buttonContainer" >
           
        //         <input className="certificate--input"  onClick={onDownloadClick} type="button" value="download"/>
              
        //     </div>

        // </div>
        <Wrapper>
            <p>Your certificate is being e-mailed to you.</p>
            <p style={{marginTop:"10px"}}>You can also download with the button below.</p>
            <PDFIcon/>
            <Button onClick={onDownloadClick}>download</Button>
        </Wrapper>
    )
}


export default Certificate
