import React, { useState, useEffect } from 'react';
import ButterToast, { POS_BOTTOM, POS_RIGHT } from 'butter-toast';
import LandingPage from './pages/LandingPage'
import { BrowserRouter as Router, Switch, Route,Redirect } from "react-router-dom"
import HomepageText from './components/HomepageText'
import HowItWorks from "./components/HowItWorks"
import Pricing from "./components/Pricing"
import BodyForm from "./pages/BodyForm/BodyForm"
import Impressum from "./pages/FooterLinks/Impressum"
import UseCases from "./pages/HeaderLinks/UseCases"
import VerifyAFiling from "./pages/HeaderLinks/VerifyAFiling"
import ActionForm from "./pages/ActionForms/ActionForm"
import FillForm from "./pages/ActionForms/FillForm"
import { Administration, AdminSettings } from "./admin"
import { getPageContent } from './api/cms'
import AppPageLayout from './layout'
import FileData from './pages/UploadData';
import UploadData from './pages/UploadData/UploadData';
import DataProtection from './pages/FooterLinks/DataProtection';
import GetStarted from './pages/HeaderLinks/GetStarted';

const App = () => {
    const [content, setContent] = useState({})

    // useEffect(() => {
    //     getPageContent({ page: 'homepage' })
    //         .then(({ data }) => setContent({ ...content, ...data[0] }))
    //         .catch(e => e)
    // }, [])

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route path="/admin" exact>
                        <Administration />
                    </Route>
                    <Route path="/admin/home" exact>
                        <AdminSettings />
                    </Route>

                    <AppPageLayout>
                        <Route path="/" exact>
                            <BodyForm childrenLeft={<ActionForm/>} childrenRight={<HomepageText content={content}/>} />
                        </Route>

                        <Route path="/upload">
                            <BodyForm childrenLeft={<UploadData />} childrenRight={<FileData/>} />
                        </Route>

                        <Route path="/use-cases" exact>
                            <BodyForm childrenLeft={<ActionForm/>}  childrenRight={<UseCases content={content}/>} />
                        </Route>

                        <Route path="/get-started" exact>
                            <BodyForm childrenLeft={<ActionForm/>}  childrenRight={<GetStarted content={content}/>} />
                        </Route>

                        <Route path="/how-it-works" exact>
                            <BodyForm childrenLeft={<ActionForm/>}  childrenRight={<HowItWorks content={content}/>} />
                        </Route>

                        <Route path="/pricing" exact>
                            <BodyForm childrenLeft={<ActionForm/>}  childrenRight={<Pricing content={content}/>} />
                        </Route>

                        <Route path="/verify" exact>
                            <BodyForm childrenLeft={<ActionForm/>} childrenRight={<VerifyAFiling />} />
                        </Route>

                        <Route path="/impressum" exact>
                            <BodyForm childrenLeft={<ActionForm children={<FillForm />} />}  childrenRight={<Impressum />} />
                        </Route>

                        {/* <Route path="/dataprotection" exact>
                            <DataProtection />
                        </Route> */}

                    </AppPageLayout>

                    <Redirect to="/" />
                </Switch>
            </Router>

            <ButterToast position={{vertical: POS_BOTTOM, horizontal: POS_RIGHT}}/>
        </div>
    );
}

export default App;
